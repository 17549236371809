import React from "react"
import Box from '@mui/material/Box';
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Stack } from '@mui/material';
import /*UserAdminHeader,*/ { IUserMenuElement } from './UAHeader';
import UserTitleCard from './UserTitleCard';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import UserAdminMenu from './UserAdminMenu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ChatIcon from '@mui/icons-material/Chat';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
// import SettingsIcon from '@mui/icons-material/Settings';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import { useHistory, useLocation } from "react-router";
import { RouteAdminUser } from "../../Admin/constant/routesName";
import { Navbar } from "../../components/Navbar";
import { LoginDialog } from "../../components/Login/LoginDialog";
import { RegisterDialog } from "../../components/Register/RegisterDialog";
import { useUser } from "../../../Hooks/UserHooks";
import CallMadeIcon from '@material-ui/icons/CallMade';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { useTranslation } from "react-i18next";
import { getRoleLabel } from "../../../utils/App";
import { Drawer } from "@material-ui/core";
import EmailIcon from '@mui/icons-material/Email';
import { UserReservationService } from "../UserReservations/services/UserReservationService";
// import LocalAtmIcon from '@material-ui/icons/LocalAtm';

const userReservationService = new UserReservationService();

export default function UserAdminContainer(props: {
    children: JSX.Element,
}) {
    const [currentMenu, setCurrentMenu] = React.useState(0);
    const [data, setData] = React.useState(0);
    const { user } = useUser();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const [deal,setDeal]=React.useState(false)
    const handleChangeCloseDealBar= ()=>{
        if(deal){
            setDeal(false) 
        }
    }

    React.useEffect(() => {
        userReservationService
        .getPendingReservations(user._id)
        .then((response) => {
            if (response.success){
                setData(response.data)
            }
        })
        .catch(err => console.log(err))
    }, [user])

    const menuList: IUserMenuElement[] = [
        // {
        //     title: t('label.dashboard'),
        //     icon: <DashboardIcon />,
        //     path: RouteAdminUser.DASHBOARD,
        //     onClick: () => { history.push(RouteAdminUser.DASHBOARD) },
        //     id:'dashboard'
        // },
        {
            title: t('label.mes-reservations'),
            icon: <BookmarkAddedIcon />,
            path: RouteAdminUser.BOOKING,
            onClick: () => { history.push(RouteAdminUser.BOOKING); setState(false) },
            id:'reservations',
            notification: 
                <div style={{position: "relative", display: "flex", justifyContent: "flex-end"}}>
                    <EmailIcon/>
                    <span className={classes.iconNotification}>{data}</span>
                </div>
        },
        {
            title: t('label.favoris'),
            icon: <FavoriteIcon />,
            path: RouteAdminUser.FAVORITE,
            onClick: () => { history.push(RouteAdminUser.FAVORITE); setState(false) },
            id:'favoris'
        },
        {
            title: t('label.services'),
            icon: <RoomServiceIcon />,
            path: RouteAdminUser.SERVICES,
            onClick: () => { history.push(RouteAdminUser.SERVICES); setState(false) },
            id:'services'
        },
        {
            title: t('label.promotions'),
            icon: <LocalOfferIcon />,
            path: RouteAdminUser.PROMOTION,
            onClick: () => { history.push(RouteAdminUser.PROMOTION); setState(false) },
            id:'promotions',
        },
        {
            title: t('label.boost-annonces'),
            icon: <CallMadeIcon />,
            path: RouteAdminUser.ANNOUNCES_ADS,
            onClick: () => { history.push(RouteAdminUser.ANNOUNCES_ADS); setState(false) },
            id: 'boost'
        },
        {
            title: t('label.chat'),
            icon: <ChatIcon />,
            path: RouteAdminUser.CHATS,
            onClick: () => { history.push(RouteAdminUser.CHATS); setState(false) },
            id:'chat'
        },
        {
            title: t('label.comptes-de-paiement'),
            icon: <CreditCardIcon />,
            path: RouteAdminUser.PAYMENT_ACCOUNT,
            onClick: () => { history.push(RouteAdminUser.PAYMENT_ACCOUNT); setState(false) },
            id:'payments'
        },
        {
            title: t('label.comptes-de-reception'),
            icon: <CreditCardIcon />,
            path: RouteAdminUser.RECEPTION_ACCOUNT,
            onClick: () => { history.push(RouteAdminUser.RECEPTION_ACCOUNT); setState(false) },
            id:'receptions'
        },
        {
            title: t('label.historique-de-transaction'),
            icon: <MonetizationOnIcon />,
            path: RouteAdminUser.TRANSACTION_HISTORY,
            onClick: () => { history.push(RouteAdminUser.TRANSACTION_HISTORY); setState(false) },
            id:'receptions'
        },
        {
            title: t('label.notifications'),
            icon: <NotificationsIcon />,
            path: RouteAdminUser.NOTIFICATION,
            onClick: () => { history.push(RouteAdminUser.NOTIFICATION); setState(false) },
            id:'notifications'
        },
        
        // {
        //     title: "Credit",
        //     icon: <LocalAtmIcon />,
        //     path: RouteAdminUser.CREDIT,
        //     onClick: () => { history.push(RouteAdminUser.CREDIT); setState(false) },
        //     id:'credit'
        // },
        // {
        //     title: t('label.profil'),
        //     icon: <AccountCircleIcon />,
        //     path: RouteAdminUser.PROFILE,
        //     onClick: () => { history.push(RouteAdminUser.PROFILE) },
        //     id:'profil'
        // },
        // {
        //     title: t('label.parametres'),
        //     icon: <SettingsIcon />,
        //     path: RouteAdminUser.SETTING,
        //     onClick: () => { history.push(RouteAdminUser.SETTING); setState(false) },
        //     id:'settings'
        // }
    ]

    React.useEffect(() => {
        setCurrentMenu(menuList.findIndex((m) => m.path === location.pathname) ?? 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openRegister, setOpenRegister] = React.useState(false);
    const [state, setState] = React.useState(false);

    //OPEN / CLOSE LOGIN MODAL
    const handleClickOpenLoginDialog = () => {
        setOpenRegister(false);
        setOpenDialog(true);
    };

    const handleCloseLoginDialog = () => {
        setOpenDialog(false);
    };

    //OPENT / CLOSE REGISTER MODAL

    const handleClickOpenRegister = () => {
        setOpenRegister(true);
        setOpenDialog(false);
    };

    const handleCloseRegister = () => {
        setOpenRegister(false);
    };

    const toggleMenu = () => {
        setState(true)
    }
    const toggleClose = () => {
        setState(false)
    }

    return (
        <Box width="100%" height="100vh">
            <Box className={classes.imageContainer} width="100%" 
                // height={200} 
            ></Box>
            <Box position="absolute" width="100%" top={0}>
                <Stack direction="column" maxWidth="105rem" marginLeft="auto" marginRight="auto" paddingTop={"100px"} spacing={3}>
                    {/* <UserAdminHeader /> */}
                    <Navbar handleClickOpenLoginDialog={handleClickOpenLoginDialog} setDeal={setDeal} deal={deal}  handleClickOpenRegister={handleClickOpenRegister} />
                    <UserTitleCard toggleMenu={toggleMenu} title={user.firstName} descComponents={[
                        <Typography key={"user-descr-item-0"} component="div">
                            {`${user.firstName ?? ''} ${user.lastName ?? ''}`}
                        </Typography>,
                        <Typography key={"user-descr-item-1"} component="div">
                            {getRoleLabel(user.role.name)}
                        </Typography>
                    ]} />
                    <Drawer
                        classes={{
                            paper: classes.paper,
                        }}
                        anchor="left"
                        open={state}
                        onClose={toggleClose}
                    >
                        <UserAdminMenu
                            onMenuChange={setCurrentMenu}
                            currentMenu={currentMenu}
                            menuLIst={menuList} 
                        />
                    </Drawer>
                    <Grid container onClick={handleChangeCloseDealBar} className={classes.affichageMobile}>
                        <Grid item lg={3} md={3} sm={3} className={classes.menu}>
                            <Box padding={2} position="sticky" top={100}>
                                <UserAdminMenu
                                    onMenuChange={setCurrentMenu}
                                    currentMenu={currentMenu}
                                    menuLIst={menuList} />
                            </Box>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={12}>
                            <Box padding={2} flex={1} height="100%" className={classes.boxMobile}>
                                {props.children}
                            </Box>
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
            <LoginDialog open={openDialog} handleClose={handleCloseLoginDialog} handleClickOpenRegister={handleClickOpenRegister} />
            <RegisterDialog open={openRegister} handleClose={handleCloseRegister} handleOpenLoginDialog={handleClickOpenLoginDialog} />
        </Box>
    )
}

/** STYLES */
const useStyles = makeStyles((theme: Theme) => 
    createStyles({
    imageContainer: {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#0e1e25",
        [theme.breakpoints.down("xs")]: {
            position: "fixed",
            zIndex: 100,
            height: 150
        },
        [theme.breakpoints.up("sm")]: {
            // position: "fixed",
            zIndex: 100,
            height: 200
        }
    },
    menu: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        },
    },
    affichageMobile: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "50px !important"
        },
    },
    boxMobile: {
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "100px !important"
        },
    },
    paper: {
        top: 150
    },
    iconNotification: {
        position: "absolute", 
        top: -10, 
        background: "blue", 
        padding: 5, 
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        width: 20,
        height: 20,
        right: -8,
        color: "#ffffff",
        fontSize: 12,
        fontWeight: "bold",
        justifyContent: "center"
    }
}));