import React from "react";
import {
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { EnhancedTableHead } from "./EnhancedTableHead";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { useTranslation } from "react-i18next";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontFamily: "Josefin Sans !important",
        },


        paper: {
            marginBottom: 50,
            backgroundColor: "#FFFFFF",
        },
        container: {
            // overflow: "auto"
            // maxWidth: "80%",
            // margin: "0 auto",

            // [theme.breakpoints.down("sm")]: {
            //     maxWidth: "95%",
            // },
        },
        title: {
            textAlign: "center",
            color: "#890608",
            fontSize: 28,
            fontFamily: "Josefin Sans",
            padding: "40px 0px",
        },
        titleHead: {
            fontSize: 18,
            fontFamily: "Josefin Sans",
            whiteSpace: "nowrap",
        },
        body: {
            fontSize: 14,
            fontFamily: "Josefin Sans",
        },
        description: {
            fontSize: 14,
            fontWeight: 300,
            fontFamily: "Josefin Sans",
        },
        boxName: {
            display: "flex",
            alignItems: "center",
            "& > img": {
                marginRight: 20,
            },
        },
        boxContainer:{
            width:94,
            height: 101
        },
        pagination: {
            fontFamily: "Josefin Sans",
        },
        table: {
            minWidth: 750,
            [theme.breakpoints.down("sm")]: {
                minWidth: "-webkit-fill-available",
            },
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        tableContainer: {
            // [theme.breakpoints.down("sm")]: {
            //     overflow: "visible !important"
            // },
        }
    })
);


export interface Data {
    id: string,
    title: string,
    announceConcerned: string,
    discount: number,
    price: number,
    startDate: string,
    endDate: string,
}

export function createData(
    id: string,
    title: string,
    announceConcerned: string,
    discount: number,
    price: number,
    startDate: string,
    endDate: string,
): Data {
    return { id, title, announceConcerned, discount, price, startDate, endDate};
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string | boolean },
    b: { [key in Key]: number | string | boolean }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function ListTable(props:{openContextMenu:(e: any, r:Data)=>void;promotions: Data[]; selected:string[]; setSelected:any; handleDeleteMultipleRow: ()=>void;}) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("id");
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(false);
    const [dense, ] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        promotion: keyof Data
    ) => {
        const isAsc = orderBy === promotion && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(promotion);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelecteds = props.promotions.map((n) => n.id);
            props.setSelected(newSelecteds);
            return;
        }
        props.setSelected([]);
    };

    const handleSelectRow = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = props.selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(props.selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(props.selected.slice(1));
        } else if (selectedIndex === props.selected.length - 1) {
            newSelected = newSelected.concat(props.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                props.selected.slice(0, selectedIndex),
                props.selected.slice(selectedIndex + 1)
            );
        }

        props.setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name: string) => props.selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, props.promotions.length - page * rowsPerPage);
    
    const {t}=useTranslation();
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar numSelected={props.selected.length} deleteAllSelectedRow={props.handleDeleteMultipleRow} />
                    <TableContainer className={classes.tableContainer}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? "small" : "medium"}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={props.selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={props.promotions.length}
                            />
                            <TableBody>
                                {stableSort<Data>((props.promotions), getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.id as string
                                        );
                                        const labelId = `enhanced-table-checkbox-${index}${row.title}`;
                                        
                                        return (
                                            <TableRow
                                                hover
                                                // onClick={e=> handleSelectRow(e, row.id as string)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        onClick={e=> handleSelectRow(e, row.id as string)}
                                                        inputProps={{
                                                            "aria-labelledby":
                                                                labelId,
                                                        }}
                                                    />
                                                </TableCell>                                                
                                                <TableCell
                                                    align="left"
                                                    className={classes.body}
                                                    width="15%"
                                                >
                                                    {row.title}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={classes.body}
                                                    width="20%"
                                                >
                                                    {row.announceConcerned}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={classes.body}
                                                    width="10%"
                                                >
                                                    {row.discount} %
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.body
                                                    }
                                                    width="10%"
                                                >
                                                {
                                                    row.price + t('annonce.labels.byNight')
                                                }
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.body
                                                    }
                                                    width="10%"
                                                >
                                                {
                                                    row.startDate
                                                }
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        classes.body
                                                    }
                                                    width="10%"
                                                >
                                                {
                                                    row.endDate
                                                }
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={classes.body}
                                                    width="10%"
                                                >
                                                    <IconButton aria-label="moreHoriz" onClick={e=>props.openContextMenu(e, row)}>
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height:
                                                (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={props.promotions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={classes.pagination}
                        labelRowsPerPage={t('listTable.labels.lignesParPage')}
                    />
                </Paper>
            </div>
        </div>
    );
}
