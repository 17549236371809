import { Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import { colors } from "../../styles/defaultColors";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CircularProgress, createStyles, makeStyles } from "@material-ui/core";
import { SortArray, useQuery } from "../../utils/App";
import { useLocation } from "react-router";
import { CountryService } from "../Admin/Countries/services/EquipmentCountry";
import React from "react";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(() =>
    createStyles({
        o: {},
        "@global": {
            ".MuiInput-underline:before, .MuiInput-underline:after": {
                display: "none"
            },
        },
    })
);


/** MAIN COMPONENT */
const countryService = new CountryService();
export const PnbPlaceSelector = (props: any) => {
    let isReadOnlyDefaultValue: boolean = props.isUpdating || false;
    const classes = useStyles();
    const location = useLocation()

    const handleChangeOption = (event: any, newValue: any) => {
        props.onChangeSelect(newValue);
    }
    let query = useQuery();
    const { i18n } = useTranslation();
    const [nomPays, setNomPays] = React.useState("")
    const [payslist, setPaysList] = React.useState<any[]>()
    React.useEffect(() => {
        countryService.getAllDestination().then(response => {
            if (response.success) {
                if (response.data !== undefined) {
                    setPaysList(response.data.sort(SortArray))
                }

            }
        })
    }, [setPaysList])
    React.useEffect(() => {
        payslist?.forEach((i) => {
            if (i?.name === query.get("search_pays")) {
                if (i18n.language === 'fr') {
                    setNomPays(i.nomFR)
                } else {
                    setNomPays(i.nomEN)
                }
            }
        })
    }, [query, i18n.language, payslist])
    return (
        <Stack direction="column" spacing={props.noHeading ? 0 : 1} marginBottom={props.noHeading ? 0 : 2}
            width={props.noHeading ? props.width ? props.width : "auto" : "100%"} >
            {!props.noHeading && <h3 style={{ fontSize: 14,marginTop:"14px", marginBottom:"6px", color: colors.black}}>{props.title}</h3>}
            {props.label ?? ''}
            {props.loadingCountry ? <CircularProgress
                                size={50}
                                style={{ color: "#890608", marginTop:8 }}
                            /> :
            <Autocomplete
                freeSolo={location.pathname.startsWith('/search')}
                id={props.id ? props.id : 'idAutoCompleteWithChips'}
                options={props.values}
                getOptionLabel={(option) => option.name ? i18n.language === 'fr' ? option.nomFR : option.nomEN : nomPays}
                className={classes.o}
                onChange={handleChangeOption}
                onInputChange={(event: React.SyntheticEvent, value: any, reason: string) => {
                    if (location.pathname.startsWith('/search')) {
                        payslist?.forEach(i => {
                            if (i18n.language === 'fr') {
                                if (i.nomFR === value) {
                                    props.setCountryInput(i.name)
                                }
                            } else {
                                if (i.nomEN === value) {
                                    props.setCountryInput(i.name) 
                                }
                            }
                        })

                    }
                }}
                value={props.selectedValues}
                forcePopupIcon={false}
                noOptionsText={'Aucun pays trouve'}
                disabled={isReadOnlyDefaultValue}
                renderInput={(params) => (props.secondStyle ?
                    <PnbStyledCustomTextFieldSeconStyle
                        {...params}
                        onChange={(event: any) => console.log(event.target.value)}
                        placeholder={props.placeHolder}
                        value={props.selectedValues?.name}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            autoComplete: 'off',
                            endAdornment: props.postIcon,
                            readOnly: true,
                        }}
                    /> :
                    (
                        props.blog ?
                            <PnbStyledCustomTextFieldBlog
                                {...params}
                                margin="dense"
                                size="small"
                                placeholder={props.placeHolder}
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    autoComplete: 'off',
                                    startAdornment: props.preIcon,
                                    endAdornment: props.postIcon,
                                    readOnly: true,
                                }}
                            />
                            :
                            <PnbStyledCustomTextField
                                {...params}
                                margin="dense"
                                size="small"
                                placeholder={props.placeHolder}
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    autoComplete: 'off',
                                    startAdornment: props.preIcon,
                                    endAdornment: props.postIcon,
                                    readOnly: true,
                                }}
                            />
                    )
                )}
            />}
        </Stack>

    )
}

/** STYLED COMPONENTS */
export const PnbStyledCustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: colors.orangePng,
        },
        '&:hover fieldset': {
            borderColor: colors.orangeDark,
        },
        '&.Mui-focused fieldset': {
            borderColor: colors.orangePng,
        },
    },
    "@global": {
        ".css-f01iyf-MuiInputBase-input-MuiOutlinedInput-input::placeholder": {
            fontFamily: "Josefin Sans!important",
        },
        ".css-b7k0tb-MuiAutocomplete-listbox.MuiAutocomplete-option": {
            fontFamily: "Josefin Sans!important",
        }
    }
})


export const PnbStyledCustomTextFieldBlog = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: "#000000",
        },
    },
    "@global": {
        ".css-f01iyf-MuiInputBase-input-MuiOutlinedInput-input::placeholder": {
            fontFamily: "Josefin Sans!important",
        },
        ".css-b7k0tb-MuiAutocomplete-listbox.MuiAutocomplete-option": {
            fontFamily: "Josefin Sans!important",
        }
    }
})


export const PnbStyledCustomTextFieldSeconStyle = styled(TextField)(() => {

    return ({
        '& .MuiOutlinedInput-root': {
            color: "#000000 !important",
            fontFamily: "Josefin Sans !important",
            border: "none",
            margin: 0,
            fontSize: 14,
            padding: 0,
            '& fieldset': {
                border: 0,
                padding: 0,
                fontSize: 14,
            },
        },
        '& input, & .MuiInputBase-input': {
            color: "#000000 !important",
            margin: "0 !important",
            padding: "0 !important",
        },
        "@global": {
            '.css-1d5sc0w-MuiFormControl-root-MuiTextField-root': {
                margin: "0 !important"
            },
            ".css-b7k0tb-MuiAutocomplete-listbox.MuiAutocomplete-option": {
                fontFamily: "Josefin Sans!important",
            }
        }
    })
})

