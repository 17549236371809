/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Stack, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { colors } from "../../../styles/defaultColors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { ITransactionReq } from "../../../utils/PaymentInterfaces";
import { ETransactionType } from "../../../utils/enum";
import { dateToStringDDMMYYYY } from "../../../utils/App";
import { CurrencyContext } from "../../../contexts/AppContext";
import { useTranslation } from "react-i18next";
import { ConvertedDeviseWithoutMoneySign } from "../../../utils/CurrencyConvertFunction";

export interface ITransactionData {
    id: string;
    name: string;
    date: Date;
    price: number;
    description?: string;
    type: "+" | "-";
}

export const BalanceItem = (props: { data: ITransactionReq }) => {
    const { t } = useTranslation();

    const data = props.data;
    const isReceiving = props.data?.type === ETransactionType.Receiving;
    const isLoan = props.data?.type === ETransactionType.OwnerPay;
    const isCancelling = props.data?.type === ETransactionType.Cancelling;
    const isBooking = props.data.reservation ? true : false;

    //console.log(props.data?.type);

    const currencyContext = React.useContext(CurrencyContext);
    const { currency } = currencyContext;
    const [priceConverted, setPriceConverted] = React.useState<number>(0);
    React.useEffect(() => {
        const dataAmount = data?.amount || 0;
        const dataCredit = data?.amountCredit || 0;
        const dataTotal = dataAmount + dataCredit;
        ConvertedDeviseWithoutMoneySign(
            isBooking ? (dataCredit === 0) ? (data.reservation?.announce?.devise ?? "XAF") : "XAF" : "XAF",
            currency,
            Number(dataTotal)
        )
            .then((value: any): any => setPriceConverted(value))
            .catch((error) => console.log(error));
    }, []);

    React.useEffect(() => {
        const dataAmount = data?.amount || 0;
        const dataCredit = data?.amountCredit || 0;
        const dataTotal = dataAmount + dataCredit;
        ConvertedDeviseWithoutMoneySign(
            (dataCredit === 0) ? (data.reservation?.announce?.devise ?? "XAF") : "XAF",
            currency,
            Number(dataTotal)
        )
            .then((value: any): any => setPriceConverted(value))
            .catch((error) => console.log(error));
    }, [currency]);

    return (
        <Stack direction="column">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                padding={2}
            >
                <Box padding={1}>
                    {(isReceiving || isLoan || isCancelling) && (
                        <AddCircleOutlineIcon sx={{ color: colors.success }} />
                    )}
                    {!isReceiving && !isLoan && !isCancelling && (
                        <RemoveCircleOutlineIcon
                            sx={{ color: colors.danger }}
                        />
                    )}
                </Box>
                <Stack flex={"1"} direction="column" justifyContent="center">
                    <Typography
                        fontSize={14}
                        fontWeight={500}
                        sx={{ color: colors.darkerGrey }}
                    >
                        {isLoan
                            ? t("transactions.label.loyer")
                            : isReceiving
                            ? t("transactions.label.remboursement")
                            : isCancelling
                            ? t("transactions.label.annulation")
                            : isBooking
                            ? t("transactions.label.reservation")
                            : t("transactions.label.sponsorisation")}
                    </Typography>
                    <Typography
                        fontSize={12}
                        fontWeight={500}
                        sx={{ color: colors.gray }}
                    >
                        {dateToStringDDMMYYYY(
                            new Date(data?.createdAt || new Date())
                        )}
                    </Typography>
                </Stack>
                <Stack direction="column" justifyContent="center">
                    <Typography
                        textAlign="center"
                        fontSize={14}
                        fontWeight={500}
                        sx={{
                            color:
                                isReceiving || isLoan || isCancelling
                                    ? colors.success
                                    : colors.danger,
                            marginRight: isReceiving ? 3 : isCancelling ? 5 : 0,
                        }}
                    >
                        {currency.toUpperCase()}{" "}
                        {priceConverted.toLocaleString()}
                    </Typography>
                    <Typography
                        textAlign="center"
                        fontSize={12}
                        fontWeight={500}
                        sx={{
                            color: colors.gray,
                            marginRight: isReceiving ? 3 : isCancelling ? 5 : 0,
                        }}
                    >
                        {isLoan
                            ? t("transactions.label.loyer-description")
                            : isReceiving
                            ? t("transactions.label.remboursement-description")
                            : isCancelling
                            ? t("transactions.label.annulation-description")
                            : isBooking
                            ? t("transactions.label.reservation-description")
                            : t(
                                  "transactions.label.sponsorisation-description"
                              )}
                    </Typography>
                </Stack>
            </Stack>
            <Divider />
        </Stack>
    );
};

/** FONCTIONS */

export const DateFormatter = (ndate: Date, dateOnly?: boolean) => {
    let date_ob = new Date(ndate);

    // adjust 0 before single digit date
    let date = ("0" + date_ob.getDate()).slice(-2);

    // current month
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // current year
    let year = date_ob.getFullYear();

    // current hours
    let hours = date_ob.getHours();

    // current minutes
    let minutes = date_ob.getMinutes();

    // current seconds
    // let seconds = date_ob.getSeconds();

    // prints date & time in YYYY-MM-DD HH:MM:SS format
    return dateOnly
        ? date + "/" + month + "/" + year
        : date + "/" + month + "/" + year + " " + hours + ":" + minutes;
};
