import React from "react";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}

const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#890608",
                    opacity: 1,
                    border: "none",
                },
            },
            "&$focusVisible $thumb": {
                color: "#890608",
                border: "6px solid #fff",
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create([
                "background-color",
                "border",
            ]),
        },
        checked: {},
        focusVisible: {},
    })
)(({ classes, ...props }: Props) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const styles = makeStyles((theme: Theme) =>
    createStyles({
        inpuText: {
            marginBottom: 14,
            marginTop: 6,
        },
        inputLabel: {
            fontSize: 14,
            fontFamily: "Josefin Sans",
            color: "#000000",
        },
        containerLabel: {
            textAlign: "left",
        },
    })
);

export default function AnnounceSwitch(props: {
    id: string;
    label: string;
    name: string;
    value: number;
    noTitle?: boolean;
    handleChange: (e: any) => void;
    onChekedChange?: (state: boolean) => void;
}) {
    const classes = styles();
    const { id, label, name, value, handleChange } = props;

    return (
        <>
            {!props.noTitle && (
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.containerLabel}
                >
                    <label className={classes.inputLabel}>{label}</label>
                </Grid>
            )}
            <Grid item lg={12} sm={12} xs={12} className={classes.inpuText}>
                <IOSSwitch
                    id={id}
                    checked={value === 1 ? true : false}
                    onChange={(e, state) => {
                        handleChange(e);
                        props.onChekedChange && props.onChekedChange(state);
                    }}
                    name={name}
                />
            </Grid>
        </>
    );
}
