import Api from "../../../https/Api";
import {
    IBookingPayment,
    IBookingPaymentOrange,
    IDioolMobileTransaction,
    ITransaction,
} from "../../../utils/PaymentInterfaces";
import { IReservation } from "../../../utils/ReservationInterfaces";
import SessionHandler from "../../../https/SessionHandler";
import { IAnnounceAd } from "../../../utils/AnnounceInterfaces";

export class ConfirmationReservationService {
    private api: Api;
    private session: SessionHandler;

    constructor() {
        this.api = new Api();
        this.session = new SessionHandler();
    }

    //get
    async getUserServicesByUserId(userId: string) {
        return this.api.simpleGet("userservices", userId);
    }

    //get reservation by Id
    async getReservationById(reservationId: string) {
        return this.api.simpleGet("reservations/get", reservationId);
    }

    async getCommissionFeeByCountryAndCityId(
        countryName: string,
        cityName: string
    ) {
        return this.api.simpleGetWithTwoParams(
            "settings/commissions-fees-country-city",
            countryName,
            cityName
        );
    }

    async getSpecialFeeByPropertyType(propertyType: string) {
        return this.api.simpleGet(
            "settings/specials-fees/property-type",
            propertyType
        );
    }

    async getTransactionById(id: string) {
        return this.api.simpleGet("transactions-details/get", id);
    }

    async getTransactionDetailByReservationId(id: string) {
        return this.api.simpleGet("transactions-details/reservation", id);
    }

    async getTransactionIdByRefundedReservationId(id: string) {
        return this.api.simpleGet(
            "transactions-id-by-refunded/reservation",
            id
        );
    }

    async sendReservationNotification(id: string) {
        return this.api.simpleGet(
            "reservations/sendReservationNotification",
            id
        );
    }

    //post
    async createPayment(paymentData: IBookingPayment) {
        return this.api.post("payments/v2/create/booking", paymentData);
    }

    async createTemporary(reservation: IReservation) {
        return this.api.post("reservations/create/temporary", reservation);
    }

    async createTemporaryAnnounceAd(announceAd: IAnnounceAd) {
        return this.api.post("announces-ads/create/temporary", announceAd);
    }

    async createDioolPayment(paymentData: IBookingPayment) {
        return this.api.post("diool/transactions/create", paymentData);
    }

    async saveReservation(reservation: IReservation) {
        return this.api.post("reservations/create", reservation);
    }

    async saveDioolTransaction(dioolTransaction: IDioolMobileTransaction) {
        return this.api.post("diool/payment-request", dioolTransaction);
    }

    async saveTransaction(transaction: ITransaction) {
        return this.api.post("transactions/create", transaction);
    }
    async getUser(id: String) {
        return this.api.simpleGet("users/get", id as string);
    }

    async createPaymentOrange(paymentDataOrange: IBookingPaymentOrange) {
        return this.api.post(
            "payments/orange/create/booking",
            paymentDataOrange
        );
    }
    async updateCredit(id: string, credit: number) {
        let state = {
            userID: id,
            credit: credit,
        };
        return this.api.post("users/updateCredit", state);
    }

    async getDohoneInfo() {
        return this.api.get("dohone");
    }
}
