/* eslint-disable no-redeclare */
import { serverConfig } from "../utils/config";
import SessionHandler from "./SessionHandler";
const Env = serverConfig;

let __instance = null;

export default class Api {
    
  controller = new AbortController();
  constructor() {
    if (__instance) return __instance;
    __instance = this;
  }

  // Method to handle PATCH requests
  async patch(api, data, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }

    try {
      const response = await fetch(`${Env.API_BASE_URL}/${api}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${SessionHandler.getToken()}`, // Adjust if needed
        },
        body: JSON.stringify(data),
        signal: cancelable ? this.controller.signal : undefined,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return {
        success: true,
        message: result.message || 'Status updated successfully',
      };
    } catch (error) {
      console.error('API error:', error);
      return {
        success: false,
        message: error.message || 'An error occurred while updating the status',
      };
    }
  }

  /**
   * Performs a HTTP POST request
   * @param {string} api the api to call
   * @param {any} data body payload
   * @returns {Promise}
   */
  post(api, data, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }

    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      let refreshToken = new SessionHandler().getRefreshToken();
      let headers = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers["Authorization"] = `${token}`;
      }
      if (refreshToken) {
        headers["expires"] = `${refreshToken}`;
      }

      fetch(`${Env.ApiRoot}/${api}`, {
        method: "POST",
        cache: "no-cache",
        headers: headers,
        redirect: "follow",
        referrerPolicy: "origin",
        body: JSON.stringify(data),
        signal: cancelable ? this.controller.signal : null,
      })
        .then((response) => {
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  }
  
   postPaypalRequestToken(apiURL, loginData, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }

    return new Promise((resolve, reject) => {
      let formData= 'grant_type=client_credentials';
      let headers=new Headers();
      headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
      headers.set('Authorization', 'Basic ' + btoa(loginData.username + ":" + loginData.password));

      fetch(`${apiURL}`, {
        method: "POST",
        cache: "no-cache",
        headers: headers,      
        redirect: "follow",
        referrerPolicy: "origin",
        body: formData,
        signal: cancelable ? this.controller.signal : null,
      })
        .then((response) => {
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  }

  
   sendRequestById(apiURL, id, accessToken) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }

    return new Promise((resolve, reject) => {

      let headers=new Headers();
      headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
      headers.set('Authorization', 'Bearer '+ accessToken);

      fetch(`${apiURL}/${id}`, {
        method: "GET",
        cache: "no-cache",
        headers: headers,      
        redirect: "follow",
        referrerPolicy: "origin",
      })
        .then((response) => {
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  }
  

  /**
   * Performs a HTTP POST request
   * @param {string} api the api to call
   * @param {string} id owner of the file
   * @param {any} data body payload
   * @returns {Promise}
   */
  postById(api, id, data = {}, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      let refreshToken = new SessionHandler().getRefreshToken();
      let headers = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers["Authorization"] = `${token}`;
      }
      if (refreshToken) {
        headers["expires"] = `${refreshToken}`;
      }

      fetch(`${Env.ApiRoot}/${api}/${id}`, {
        method: "PUT",
        cache: "no-cache",
        headers: headers,
        redirect: "follow",
        referrerPolicy: "origin",
        body: JSON.stringify(data),
        signal: cancelable ? this.controller.signal : null,
      })
        .then((response) => {
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Performs a HTTP POST request
   * @param {string} api the api to call
   * @param {string} id owner of the file
   * @param {any} file the file itself
   * @returns {Promise}
   */
  postFile(api, id, file, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      let refreshToken = new SessionHandler().getRefreshToken();
      let headers = {
        // 'Content-Type': 'application/json', //Le navigateur se chargera de mettre la bonne content-ype
      };
      if (token) {
        headers["Authorization"] = `${token}`;
      }
      if (refreshToken) {
        headers["expires"] = `${refreshToken}`;
      }

      fetch(`${Env.ApiRoot}/${api}/${id}`, {
        method: "PUT",
        cache: "no-cache",
        headers: headers,
        redirect: "follow",
        referrerPolicy: "origin",
        body: file,
        signal: cancelable ? this.controller.signal : null,
      })
        .then((response) => {
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  postFileupload(api,  file, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      let refreshToken = new SessionHandler().getRefreshToken();
      let headers = {
        // 'Content-Type': 'application/json', //Le navigateur se chargera de mettre la bonne content-ype
      };
      if (token) {
        headers["Authorization"] = `${token}`;
      }
      if (refreshToken) {
        headers["expires"] = `${refreshToken}`;
      }

      fetch(`${Env.ApiRoot}/${api}`, {
        method: "POST",
        cache: "no-cache",
        headers: headers,
        redirect: "follow",
        referrerPolicy: "origin",
        body: file,
        signal: cancelable ? this.controller.signal : null,
      })
        .then((response) => {
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Performs a HTTP GET request
   * @param {string} api the api to call
   * @param {any} params URL parameters
   */
  get(api, params = null) {
    var url = new URL(`${Env.ApiRoot}/${api}`);
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (!response.ok)
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((err) => reject(err));
    });
  }

  /**
   * Performs a HTTP GET request
   * @param {string} api the api to call
   * @param {any} params URL parameters
   */
   downloadFile(api, params = null) {
    var url = new URL(`${Env.ApiRoot}/${api}`);
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      }).then((response) => {
        if(!response.ok) {
          throw Error(response.statusText);
        }

        return response.blob()
      }).then(blob => {
        if (blob !== null){
          var a =  document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = 'AfriqPnB';
          document.body.appendChild(a)
          a.click();
          a.remove()
        }
      }).then(file => resolve(file))
      .catch((err) => reject(err));
  });
   }

  /**
   * Performs a HTTP GET request
   * @param {string} api the api to call
   * @param {any} params URL parameters
   */
   downloadFileIFrame(api, params = null) {
    var url = new URL(`${Env.ApiRoot}/${api}`);
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }
    return new Promise((resolve, reject) => {
      let iframe = document.createElement('iframe')
            iframe.id = `id_${url}`
            iframe.src = url
            iframe.style.display = 'none'
            document.body.appendChild(iframe)
            setTimeout(() => {
                iframe.remove()
            }, 5000)
            resolve(true)
    })
   }
  
  /**
   * Performs a HTTP GET request
   * @param {string} api the api to call
   * @param {any} params URL parameters
   */
  getWithURL(url, params = null) {
    var url = new URL(`${url}`);
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (!response.ok)
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((err) => reject(err));
    });
  }

  
  /**
   * Performs a HTTP GET request
   * @param {string} api the api to call
   * @param {any} params URL parameters
   */
   getQuery(api, params = null) {
    var url = new URL(`${Env.ApiRoot}/${api}`);
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
     }
     
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (!response.ok)
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((err) => reject(err));
    });
  }

  /**
   * Performs a HTTP GET request
   * @param {string} api the api to call
   * @param {any} params URL parameters
   */
  simpleGet(api, param) {
    var url = `${Env.ApiRoot}/${api}/${param}`;

    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (!response.ok)
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((err) => reject(err));
    });
  }



  /**
   * Performs a HTTP GET request
   * @param {string} api the api to call
   * @param {any} params URL parameters
   */
  simpleGetWithTwoParams(api, param1, param2) {
    var url = `${Env.ApiRoot}/${api}/${param1}/${param2}`;

    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (!response.ok)
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((err) => reject(err));
    });
  }

  /**
   * Performs a HTTP GET request
   * @param {string} api the api to call
   * @param {string} filter filter of  the search
   * @param {XMLHttpRequestBodyInit} params URL parameters
   */
  simpleGetWithFilter(api, filter) {
    var url = `${Env.ApiRoot}/${api}`;

    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
          filter: JSON.stringify(filter),
        },
      })
        .then((response) => {
          if (!response.ok)
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((err) => reject(err));
    });
  }
/**
   * Performs a HTTP GET request
   * @param {string} api the api to call
   * @param {string} filter filter of  the search
   * @param {XMLHttpRequestBodyInit} params URL parameters
   */
 simpleGetWithSearch(api, filter) {
  var url = `${Env.ApiRoot}/${api}`;

  return new Promise((resolve, reject) => {
    // let token = new SessionHandler().getAccessToken();
    fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: filter
    })
      .then((response) => {
        if (!response.ok)
          response
            .json()
            .then((json) => reject(json))
            .catch((err) => reject(err));
        else {
          response
            .json()
            .then((json) => resolve(json))
            .catch((err) => reject(err));
        }
      })
      .catch((err) => reject(err));
  });
}

  /**
   * Performs a HTTP PUT request
   * @param {string} api the api to call
   * @param {any} data body payload
   * @returns {Promise}
   */
   put(api, data, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }

    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      let refreshToken = new SessionHandler().getRefreshToken();
      let headers = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers["Authorization"] = `${token}`;
      }
      if (refreshToken) {
        headers["expires"] = `${refreshToken}`;
      }

      fetch(`${Env.ApiRoot}/${api}`, {
        method: "PUT",
        cache: "no-cache",
        headers: headers,
        redirect: "follow",
        referrerPolicy: "origin",
        body: JSON.stringify(data),
        signal: cancelable ? this.controller.signal : null,
      })
        .then((response) => {
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  }

  /**
   * Performs a HTTP DELETE request
   * @param {string} api the api to call
   * @param {any} param URL parameters
   */
  simpleDeleteById(api, param) {
    var url = `${Env.ApiRoot}/${api}/${param}`;

    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "DELETE",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) reject(response);
          else
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  
  /**
   * Performs a HTTP DELETE request
   * @param {string} api the api to call
   * @param {any} param1 URL parameters
   * @param {any} param2 URL parameters
   */
   simpleDeleteByParams(api, param1, param2) {
    var url = `${Env.ApiRoot}/${api}/${param1}/${param2}`;

    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "DELETE",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) reject(response);
          else
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }


  /**
   * Performs a HTTP DELETE request
   * @param {string} api the api to call
   * @param {any} params URL parameters
   */
  deleteMany(api, params) {
    var url = `${Env.ApiRoot}/${api}`;

    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "DELETE",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(params),
      })
        .then((response) => {
          if (!response.ok) reject(response);
          else
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  /**
   * Performs a HTTP DELETE request
   * @param {string} api the api to call
   * @param {any} params URL parameters
   */
  delete(api, params = null) {
    var url = new URL(`${Env.ApiRoot}/${api}`);
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "DELETE",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) reject(response);
          else
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  /**
   * Performs a HTTP DELETE request
   * @param {string} api the api to call
   * @param {string} idProperty owner of the property
   * @param {string} photoName name of the photo( required for deleting)
   */
  deletePropertyPhoto(api, idProperty, photoName) {
    var url = new URL(`${Env.ApiRoot}/${api}/${idProperty}/${photoName}`);

    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      fetch(url, {
        method: "DELETE",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) reject(response);
          else
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  abort() {
    if (this.controller && this.controller.abort) {
      this.controller.abort();
    }
  }

  postFormData(api, data, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      let headers = {
        // 'Content-Type': 'application/json', //Null besoin de ça pour tranférer des fichiers
      };
      if (token) {
        headers["Authorization"] = `${token}`;
      }

      fetch(`${Env.ApiRoot}/${api}`, {
        method: "POST",
        cache: "no-cache",
        headers: headers,
        redirect: "follow",
        referrerPolicy: "origin",
        body: data,
        signal: cancelable ? this.controller.signal : null,
      })
        .then((response) => {
          console.log(response);
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Performs a HTTP POST request
   * @param {string} api the api to call
   * @param {string} id owner of the file
   * @param {string} idA the file itself
   * @returns {Promise}
   */
   postID(api, id, idA, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      let refreshToken = new SessionHandler().getRefreshToken();
      let headers = {
        // 'Content-Type': 'application/json', //Le navigateur se chargera de mettre la bonne content-ype
      };
      if (token) {
        headers["Authorization"] = `${token}`;
      }
      if (refreshToken) {
        headers["expires"] = `${refreshToken}`;
      }

      fetch(`${Env.ApiRoot}/${api}/${id}/${idA}`, {
        method: "PUT",
        cache: "no-cache",
        headers: headers,
        redirect: "follow",
        referrerPolicy: "origin",
        signal: cancelable ? this.controller.signal : null,
      })
        .then((response) => {
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  /**
   * Performs a HTTP POST request
   * @param {string} api the api to call
   * @param {string} id body payload
   * @param {any} data body payload
   * @returns {Promise}
   */
  postFormDataById(api, id, data, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      let headers = {
        // 'Content-Type': 'application/json', //Null besoin de ça pour tranférer des fichiers
      };
      if (token) {
        headers["Authorization"] = `${token}`;
      }

      fetch(`${Env.ApiRoot}/${api}/${id}`, {
        method: "PUT",
        cache: "no-cache",
        headers: headers,
        redirect: "follow",
        referrerPolicy: "origin",
        body: data,
        signal: cancelable ? this.controller.signal : null,
      })
        .then((response) => {
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  postByIdSimple(api, id, cancelable = false) {
    if (this.controller.signal.aborted) {
      this.controller = new AbortController();
    }
    return new Promise((resolve, reject) => {
      let token = new SessionHandler().getAccessToken();
      let refreshToken = new SessionHandler().getRefreshToken();
      let headers = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers["Authorization"] = `${token}`;
      }
      if (refreshToken) {
        headers["expires"] = `${refreshToken}`;
      }
  
      fetch(`${Env.ApiRoot}/${api}/${id}`, {
        method: "PUT",
        cache: "no-cache",
        headers: headers,
        redirect: "follow",
        referrerPolicy: "origin",
        body: JSON.stringify(),
        signal: cancelable ? this.controller.signal : null,
      })
        .then((response) => {
          if (!response.ok) {
            response
              .json()
              .then((json) => reject(json))
              .catch((err) => reject(err));
          } else {
            response
              .json()
              .then((json) => resolve(json))
              .catch((err) => reject(err));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

