export enum RouteAdmin {
    PROFIL = "/admin/myprofil",
    ACTIVITY = "/admin/activity",
    ACTIVITY_ANNOUNCE = "/admin/activity/announces",
    ACTIVITY_RESERVATION = "/admin/activity/reservations", 
    ACTIVITY_VERIFICATION = "/admin/activity/verifications",
    ACTIVITY_PAYMENT = "/admin/activity/payments",
    ACTIVITY_REVIEW = "/admin/activity/reviews",
    ACTIVITY_REVIEW_EDIT = "/admin/activity/reviews/edit",
    ACTIVITY_MESSAGE = "/admin/activity/messages",
    CATALOG = "/admin/catalog",
    CATALOG_PROPERTIES = "/admin/catalog/properties",
    CATALOG_VEHICLES = "/admin/catalog/vehicles",
    COUNTRY = "/admin/countries",
    COUNTRY_EDIT = "/admin/coutries/edit",
    COUNTRY_LIST = "/admin/coutries/list",
    ROLE = "/admin/roles",
    ROLE_EDIT = "/admin/roles/edit",
    ROLE_ASSIGN = "/admin/roles/assigns",
    ROLE_PRIVILEGE = "/admin/roles/privileges",
    SALES = "/admin/sales",
    SALES_DASHBOARD = "/admin/sales-dashboard",
    SALES_DETAIL = "/admin/sales-detail",
    WEB_ANALYTICS = "/admin/web-analytics",
    WEB_ANALYTICS_DASH = "/admin/web-analytics-dashboard",
    CRM = "/admin/crm",
    CRM_LIST = "/admin/crm/list",
    CRM_CLT_DASH = "/admin/crm/clt-dashboard",
    // DASHBOARD = "/admin/dashboard",
    PROPERTY = "/admin/property",
    ANNOUNCE = "/admin/announce",
    ANNOUNCE_EDIT = "/admin/announce/edit",
    RESERVATION = "/admin/reservations",
    VALIDATION = "/admin/validations",
    VALIDATION_ANNOUNCE = "/admin/validations/announce",
    VALIDATION_PROPERTY = "/admin/validations/property",
    EQUIPMENT = "/admin/equipments",
    EQUIPMENT_CATEG = "/admin/equipments/categories",
    EQUIPMENT_EDIT = "/admin/equipments/edit",
    EQUIPMENT_LIST = "/admin/equipments/list",
    DASHBOARD_USER = "/admin/dashboard/user",
    DASHBOARD_STATS = "/admin/dashboard/stats",
    BLOG_CATEG = "/admin/blog/categories",
    BLOG_TAG = "/admin/blog/tags",
    BLOG_PARTNER = "/admin/blog/partners",
    BLOG_THEME = "/admin/blog/themes",
    BLOG = "/admin/blog",
    BLOG_EDIT = "/admin/blog/edit",
    BLOG_LIST = "/admin/blog/list",
    GUIDE = "/admin/guide",
    GUIDE_EDIT = "/admin/guide/edit",
    GUIDE_LIST = "/admin/guide/list",
    POPUP = "/admin/popup",
    POPUP_EDIT = "/admin/popup/edit",
    POPUP_LIST = "/admin/popup/list",
    POPUP_TIME = "/admin/popup/time",
    HOTEL = "/admin/hotels",
    HOTEL_EDIT = "/admin/hotels/list/modif/hotel",
    HOTEL_LIST = "/admin/hotels/list",
    ACCOUNT = "/admin/accounts",
    ACCOUNT_LOGIN = "/admin/accounts/login",
    ACCOUNT_EDIT = "/admin/accounts/edit",
    ACCOUNT_PREVIEW = "/admin/accounts/preview",
    ACCOUNT_SUPER_ADMIN = "/admin/accounts/super-admin",
    ACCOUNT_ADMIN_SUPPORT = "/admin/accounts/admin-support",
    ACCOUNT_COUNTRY_ADMIN = "/admin/accounts/country-admin",
    ACCOUNT_SALES_AGENT = "/admin/accounts/sales-agent",
    ACCOUNT_GUEST = "/admin/accounts/guests",
    ACCOUNT_HOTEL_ADMIN = "/admin/accounts/hotel-admin",
    ACCOUNT_CLERK = "/admin/accounts/clerk",
    ACCOUNT_HOST = "/admin/accounts/host",
    ACCOUNT_TRAVELLER = "/admin/accounts/traveller",
    ACCOUNT_ALL = "/admin/accounts/all",
    SETTING = "/admin/setting",
    SETTING_ADS = "/admin/setting/ads",
    SETTING_ADS_EDIT = "/admin/setting/ads/:id",
    SETTING_CURRENCY_SELECTION = "/admin/setting/currency",
    SETTING_CURRENCY_SELECTION_EDIT = "/admin/setting/currency/edit",
    SETTING_CURRENCY_SELECTION_ADD = "/admin/setting/currency/add",
    ACTIVITY_DEMANDEHOTEL = "/admin/devenir-hotel",
    SETTING_PLATEFORM_FEES = "/admin/setting/platform-fees/",
    SETTING_FOOTER_LINKS = "/admin/setting/footer-links",
    SETTING_PROPERTY_TYPES = "/admin/setting/property-types",
}

export enum RouteAdminUser {
    DASHBOARD = "/users/admin",
    NOTIFICATION = "/users/admin/notification",
    PAYMENT_ACCOUNT = "/users/admin/payment-account",
    RECEPTION_ACCOUNT = "/users/admin/reception-account",
    TRANSACTION_HISTORY = "/users/admin/transaction-history",
    PROFILE = "/users/admin/profile",
    SETTING = "/users/admin/setting",
    FAVORITE = "/users/admin/favorite",
    CHATS = "/users/admin/chats",
    PROMOTION = "/users/admin/promotion",
    SERVICES = "/users/admin/services",
    ANNOUNCES_ADS = "/users/admin/listings-ads",
    ANNOUNCES_ADS_DISABLED = "/users/admin/listings-ads/state/:id",
    BOOKING = "/users/admin/booking",
    CREDIT = "/users/admin/credit",
}

export const routeAdminMapping: RouteMapping[] = [
    { path: RouteAdmin.PROPERTY },
    { path: RouteAdmin.RESERVATION },
    { path: RouteAdmin.VALIDATION },
    { path: RouteAdmin.VALIDATION_ANNOUNCE },
    { path: RouteAdmin.VALIDATION_PROPERTY },
    { path: RouteAdmin.ANNOUNCE },
    { path: RouteAdmin.EQUIPMENT_EDIT },
    { path: RouteAdmin.EQUIPMENT_CATEG },
    { path: RouteAdmin.EQUIPMENT_LIST },
    { path: RouteAdmin.DASHBOARD_STATS },
    { path: RouteAdmin.DASHBOARD_USER },
    { path: RouteAdmin.BLOG_EDIT },
    { path: RouteAdmin.BLOG_LIST },
    { path: RouteAdmin.ACCOUNT_ALL },
];

interface RouteMapping {
    path: string;
    parent?: string[];
}
