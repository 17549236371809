import Api from "../../../../https/Api";
import i18n from "../../../../i18n/config";
import { IUser } from "../../../../utils/UserInterfaces";

export let typeRolesAccounts = [
    // { name: 'super admin', label: i18n.t("SignUp.super-admin"), selected: true },
    { name: "admin", label: i18n.t("SignUp.admin") },
    { name: "hotel admin", label: i18n.t("SignUp.hotel-admin") },
    { name: "clerk", label: i18n.t("SignUp.clerk") },
    { name: "sales agent", label: i18n.t("SignUp.sales-agent") },
    { name: "host", label: i18n.t("SignUp.host") },
    { name: "traveller", label: i18n.t("SignUp.traveller"), selected: true },
];

export let typeRolesAccountsForAdmin = [
    { name: "hotel admin", label: i18n.t("SignUp.hotel-admin") },
    { name: "clerk", label: i18n.t("SignUp.clerk") },
    { name: "sales agent", label: i18n.t("SignUp.sales-agent") },
    { name: "host", label: i18n.t("SignUp.host") },
    { name: "traveller", label: i18n.t("SignUp.traveller"), selected: true },
];

export let typeRolesAccount_super_admin = [
    // { name: 'super admin', selected: true },
    { name: "admin", label: i18n.t("SignUp.admin") },
    { name: "hotel admin", label: i18n.t("SignUp.hotel-admin") },
    { name: "clerk", label: i18n.t("SignUp.clerk") },
    { name: "sales agent", label: i18n.t("SignUp.sales-agent") },
    { name: "host", label: i18n.t("SignUp.host") },
    { name: "traveller", label: i18n.t("SignUp.traveller"), selected: true },
];

export let typeRolesAccount_admin = [
    // { name: 'super admin', selected: true },
    // { name: 'admin' },
    { name: "hotel admin" },
    { name: "clerk" },
    { name: "sales agent" },
    { name: "host" },
];

export class Accountservice {
    private api: Api;

    constructor() {
        this.api = new Api();
    }

    register(user: any): Promise<any> {
        return this.api.post("users/create", user);
    }

    async getRoles() {
        return this.api.get("roles");
    }

    async getUserById(userId: string) {
        return this.api.simpleGet("users/get", userId);
    }

    async updateUser(id: string, user: IUser) {
        return this.api.postById("users", id, user);
        // return this.api.postFormDataById('users',id, user)
    }

    async uploadAvatar(userId: string, avatar: File) {
        return this.api.postById("users", userId, avatar);
    }

    //get
    async getListUsers(filter: any, page: number = 1, limit: number = 10) {
        return this.api.simpleGetWithFilter(`users`, filter);
    }

    //GET all users
    async getAllUsers() {
        return this.api.simpleGetWithFilter('users/all', '');
    }

    //delele

    //delete
    async deleteAccount(id: string) {
        return this.api.simpleDeleteById(`users/delete`, id);
    }

    async deletesAccount(ids: string[]) {
        const param = {
            ids: ids,
        };
        return this.api.deleteMany("users/deleteMany", param);
    }
}
