import Api from "../../../https/Api";

export class HomeService {
    private api: Api;
    constructor() {
        this.api = new Api();
    }

    //get
    async getListAnnouncesPerPage(page: number = 1, limit: number = 10) {
        return this.api.get(`announces_sliide/${page}/${limit}`);
    }
    async getListAnnounces() {
        return this.api.get(`announces`);
    }
    async getListAnnouncesAc() {
        return this.api.get(`announceAccueil`);
    }
     // Fetch announcements filtered by searchType
     async getListAnnouncesBySearchType(searchType: string, page: number = 1, limit: number = 10) {
        return this.api.get(`announces/searchType/${searchType}/${page}/${limit}`);
    }
    
}
